import "./Offer.css";
import OfferData from "./OfferData";
import OcaenCargo from "../assets/ocean-cargo.jpg";
import AirFreight from "../assets/air-freight.jpg";
import CargoLogistics from "../assets/ cargo-logistics.jpg";


const Offer = () => {
    return (
        <div className="offer">
            <h1>What We Offer</h1>
            <div className="service-card">
                <OfferData
                    imageLink="/ocean-shipping"
                    serviceImage={OcaenCargo}
                    service="Maritime Ocean Cargo"
                    heading="Maritime Ocean Cargo"
                    text="Swift and secure ocean cargo services connecting you to the world's ports."
                />
                <OfferData
                    imageLink="/air-freight"
                    serviceImage={AirFreight}
                    service="Air Freight"
                    heading="Air Freight"
                    text="High-speed air freight solutions ensuring your goods reach their destination with speed and reliability.
"
                />
                <OfferData
                    imageLink="/cargo-logistics"
                    serviceImage={CargoLogistics}
                    service="Freight Forwarding/Cargo Logistics"
                    heading="Freight Forwarding/Cargo Logistics"
                    text=" Comprehensive logistics services designed to streamline the movement of your cargo, optimizing every step of the journey."
                />
            </div>
        </div>

    );
}

export default Offer;