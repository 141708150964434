
import FaqComp from "../components/FaqComp";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Strength from "../components/Strength";

const Faq = () => {
    return (
        <>
            <Navbar />
            <FaqComp />
            <Strength />
            <Footer />
        </>
    );
}

export default Faq;