import './FaqSection.css';

const FaqSection = () => {
    return (
        <div className="faqSection">
            <div className='buttonAndText'>
                <div className='faqText'>
                    <h1>Join us <br /> <span className='voyage'> on this voyage. </span></h1>
                    <p className='firstP'>TechPort Africa invites you to embark on this exciting maritime journey with us. <br /> Whether you're a seasoned importer/exporter or a newcomer to global trade, <br />we have the expertise, passion, and dedication to navigate your cargo to success.</p>
                    <p className='secondP'>At TechPort Africa, we don't just ship cargo; we forge connections, <br />empower businesses, and navigate the seas of opportunity.</p>
                    <p className='thirdP'>TechPort Africa - Navigating Excellence, Connecting Continents....through a SEAMLESS, AUTOMATED &SOLUTION driven approach.</p>
                    <div className='twoButtons'>
                        {/* <button type="submit" className='submit' >
                            Get in touch
                        </button> */}
                        <a href='/contact' className='link'>
                            Get in touch
                        </a>
                        <a href='/faq' className='link'>
                            Check our FAQs
                        </a>
                    </div>

                </div>
                <div className='faqImage'>
                    <img src='https://images.unsplash.com/photo-1592963219385-53b52b371dd1?q=80&w=2836&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' alt='Maritime-shipping' />
                    {/* <div className='operate'>
                        <h1>How do we operate?</h1>
                        <a href='/faq' className='link'>
                            Check our FAQs
                        </a>
                    </div> */}

                </div>

            </div>
        </div>
    );

}

export default FaqSection;