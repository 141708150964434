

import "./Logistics.css";
import LogisticsData from "./LogisticsData";


const Logistics = () => {

    return (

        <LogisticsData
            CName="logistics"
            logHeading="Logistics Made Easy"
            logP="Your cargo logistics, freight forwarding and shipping will never be a hassle again."
            logSection="logistics-section"
            logText="logistics-text"
            logH2="Your Trusted Partner"
            P2="We are a leading name in the world of cargo logistics, freight forwarding, and
            shipping services in Africa. We aim to facilitate seamless cargo movement, enhance supply
            chain efficiency, and support economic growth by providing top-tier services that meet
            the unique needs of our diverse clientele. Let TechPort Africa Cabotage LTD be your trusted partner
            in navigating the world of logistics and shipping. Experience the difference that
            dedication, expertise, and innovation can make in your supply chain. We look forward to serving you."
            logImage="logiistics-image"
            img1="https://plus.unsplash.com/premium_photo-1661880749508-71d9e7170508?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            img2="https://plus.unsplash.com/premium_photo-1683120796013-f2f18451a907?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />

    );
}

export default Logistics;


