import StrengthCards from "./Strength Cards";
import "./Strength.css";

const Strength = () => {
    return (
        <div className="main">
            <div className="strength">
                <div className="strength-text">
                    <h1>
                        Delivering a <span className="seamless">seamless</span> service is our core
                        {/* Securing your <span className="confidence">confidence</span>  is our Top Priority. */}
                    </h1>
                    <p>
                        We are bringing an end to all your logistics problems by ensuring that your cargo reaches its destination, whether it's across the continent or anywhere the world.
                        {/* Say goodbye to your transportation headaches. Experience worry-free shipping with our comprehensive freight solutions. Let us handle it all for you */}
                    </p>
                    <a href="/get-a-quote" className="strenghtButton">
                        Get a Quote
                    </a>
                </div>


                <div className="all-cards">
                    <div className="first-row">
                        <StrengthCards
                            cardText="card-text"
                            strengthCard="strength-cards"
                            strengthH1="Integrity"
                        />
                        <StrengthCards
                            cardText="card-text"
                            strengthCard="strength-cards-black"
                            strengthH1="Reliability"
                        />
                    </div>
                    <div className="second-row">
                        <StrengthCards
                            cardText="card-text"
                            strengthCard="strength-cards-black"
                            strengthH1="Competence"
                        />
                        <StrengthCards
                            cardText="card-text"
                            strengthCard="strength-cards"
                            strengthH1="Timely Delivery"
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Strength;