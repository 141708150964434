import "./ServicesIntro.css";
import Airplane from "../assets/28892-removebg-preview.png";
const ServiceIntro = () => {
    return (
        <div className="service-main">
            <div className="service-intro">
                <img src={Airplane} alt="freight-forwarding" />
                <h1>Revolutionizing cargo logistics, freight forwarding, and shipping services in Africa </h1>
            </div>
        </div>
    );
}

export default ServiceIntro;