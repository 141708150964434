import "./Offer.css";

const OfferData = (props: any) => {
    return (
        <div className="o-card">
            <div className="o-image">
                <a href={props.imageLink}><img alt={props.service} src={props.serviceImage} /></a>
            </div>
            <h4>{props.heading}</h4>
            <p>{props.text}</p>
        </div>
    );
}

export default OfferData;