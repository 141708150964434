import { motion, useScroll, useTransform } from "framer-motion";
import "./Logistics.css";
import { useRef } from "react";

const LogisticsData = (props: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["0 1", "1.33 1"]
    });
    const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
    const opacity = useTransform(scrollYProgress, [0, 1], [0.6, 1]);
    return (
        <motion.div className={props.CName} ref={ref} style={
            {
                scale: scaleProgress,
                opacity: opacity
            }
        }>
            <h1>{props.logHeading}</h1>
            <p>{props.logP}</p>

            <div className={props.logSection}>
                <div className={props.logText}>
                    <h2>{props.logH2}</h2>
                    <p>{props.P2}</p>
                </div>

                <div className={props.logImage}>
                    <img alt="cargo" src={props.img1} />
                    <img alt="freight" src={props.img2} />
                </div>
            </div>



        </motion.div>
    );
}

export default LogisticsData;