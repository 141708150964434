import { useState } from "react";
import "./OceanShippingButton.css"; 
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";


const AirFreightButton = () => {
    const [pickupAddress, setOrigin] = useState('');
    const [date, setDate] = useState(new Date());
    const [destination, setDropOff] = useState('');
    const [goods, setGood] = useState('');
    const [weight, setWeight] = useState('');      
    const [fullName, setfullName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const body = {
            email: email,
            fullName: fullName,
            pickupAddress: pickupAddress,
            typeOfGoods: goods,            
            weight: weight,
            destination: destination,
            number: number,            
            date: new Date(),
        }
        console.log("let's have");
        
        // /.netlify/functions / airFreight
        fetch('https://techport-ms.com/.netlify/functions/airFreight', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
            .then(() => {
                Swal.fire({
                    text: "We will be in touch with you soon",
                });
            }).catch((error) => {
                console.log(error);
                Swal.fire({
                    text: "Sorry, an error occurred",
                });
            });
        setEmail('');
        setfullName("");
        setGood("");
        setWeight("");       
        setOrigin("");
        setDropOff("");        
        setNumber("");

    }

    return (
        <div className="shippingField">
            <form onSubmit={handleSubmit}>
                <div className="shippingField1">
                    <input type="text" name="pickup-address" placeholder="Pickup Address" value={pickupAddress}
                        onChange={(e) => setOrigin(e.target.value)} />
                    <input type="text" name="destination" placeholder="Address of Destination" value={destination}
                        onChange={(e) => setDropOff(e.target.value)} />
                    <input type="text" name="goods" placeholder="Type of goods/parcel" value={goods}
                        onChange={(e) => setGood(e.target.value)} />
                    <input type="text" name="weight" placeholder="Weight" value={weight}
                        onChange={(e) => setWeight(e.target.value)} />
                    <input type="text" name="full-name" placeholder="Enter your Full Name" value={fullName}
                        onChange={(e) => setfullName(e.target.value)} />
                </div>
                <div className="shippingField2">                                                                               
                    <input type="text" name="phone-number" placeholder="Enter your Phone Number" value={number}
                        onChange={(e) => setNumber(e.target.value)} />
                    <input type="text" name="user-email" placeholder="Enter your email address" value={email}
                        onChange={(e) => setEmail(e.target.value)} />                              
                    <button type="submit" >
                        Process
                    </button>
                </div>

            </form>
        </div>
    );
}

export default AirFreightButton;