
const TwoColumns = (props: any) => {
    return (
        <div className={props.column}>
            <h2>{props.h2}</h2>
            <p>{props.p}</p>
        </div>
    );
}

export default TwoColumns;