// import { useState } from "react";
// import OceanShippingButton from "./OceanShippingButton";
// import "./OrderContainer.css";
// import CargoLogisticsButton from "./CargoLogisticsButton";
// import AirFreightButton from "./AirFreightButton";

// const OrderContainer = () => {
//     const [cargoClicked, setCargoClicked] = useState (false);
//     const [shippingClicked, setShippingClicked] = useState(true);
//     const [freightClicked, setFreightClicked] = useState(false);

//     const handleCargoClick = () => setCargoClicked(!cargoClicked);
//     const handleShippingClick = () => setShippingClicked(!shippingClicked);
//     const handlFreightClick = () => setFreightClicked(!freightClicked);
//     return (
//         <div className="OrderBox">
//             <div className="orderRow">
//                 <div className="orderButtons">
//                     <button className="orderButton" onClick={handleShippingClick}>
//                         <i className="fa-sharp fa-solid fa-ship"></i>  Ocean Shipping
//                     </button >
//                     <button className="orderButton" onClick={handleCargoClick}>
//                         <i className="fa solid fa-truck"></i> Cargo Logistics
//                     </button>
//                     <button className="orderButton" onClick={handlFreightClick}>
//                         <i className="fa-solid fa-jet-fighter"></i>Air Freight
//                     </button>                
//                 </div>
//                 <div>
//                     {(() => {
//                         if (shippingClicked) {
//                             return (
//                                 <OceanShippingButton />
//                             )
//                         } else if (cargoClicked) {
//                             return (
//                                 <CargoLogisticsButton />
//                             )
//                         } else if (freightClicked){
//                             return (
//                                 <AirFreightButton />
//                             )
//                         }
//                     })()}
//                 </div>
//                 {/* {shippingClicked && <OceanShippingButton />}
//                 {cargoClicked && <CargoLogisticsButton />} */}
//                 {/* {freightClicked && <AirFreightButton />} */}
//             </div>
//         </div>
//     );
// }

// export default OrderContainer;

// const OrderContainer = () => {
//     const [toggle, setToggle] = useState(1)
//     const toggleTab = (index:any) => {
//         setToggle(index);
//     }
//     return (
//         <div className="OrderBox"> 
//             <div className="bloc-tabs">
//                 <div className={toggle === 1 ? "tabs active-tabs" : "tabs" } onClick={(() => {
//                     toggleTab(1)
//                 })} ><i className="fa-sharp fa-solid fa-ship"></i>  Ocean Shipping</div>
//                 <div className={toggle === 2 ? "tabs active-tabs" : "tabs"} onClick={(() => {
//                     toggleTab(2)
//                 })}><i className="fa solid fa-truck"></i> Cargo Logistics</div>
//                 <div className={toggle === 3 ? "tabs active-tabs" : "tabs"} onClick={(() => {
//                     toggleTab(3)
//                 })}><i className="fa-solid fa-jet-fighter"></i>Air Freight</div>
//             </div>

//             <div className="content-tabs">
//                 <div className={toggle === 1 ? "content active-content" : "content"}>
//                     <OceanShippingButton />
//                 </div>
//                 <div className={toggle === 2 ? "content active-content" : "content"}>
//                     <CargoLogisticsButton />
//                 </div>
//                 <div className={toggle === 3 ? "content active-content" : "content"}>
//                     <AirFreightButton />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default OrderContainer;

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./OrderContainer.css";
import OceanShippingButton from './OceanShippingButton';
import CargoLogisticsButton from './CargoLogisticsButton';
import AirFreightButton from './AirFreightButton';

const OrderContainer = () => {

    return (
        <div className="OrderBox">
            
            <Tabs defaultIndex={1}>
                <TabList>
                    <Tab><i className="fa-sharp fa-solid fa-ship"></i>  Ocean Shipping</Tab>
                    <Tab><i className="fa solid fa-truck"></i> Cargo Logistics</Tab>                    
                    <Tab><i className="fa-solid fa-jet-fighter"></i>Air Freight</Tab>
                </TabList>

                <TabPanel>
                    <OceanShippingButton />
                </TabPanel>
                <TabPanel>
                    <CargoLogisticsButton />
                </TabPanel>                
                <TabPanel>
                    <AirFreightButton />
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default OrderContainer;
