import { useState } from "react";
import "./OceanShippingButton.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const CargoLogisticsButton = () => {
    const [value, setValue] = useState('');
    const [origin, setOrigin] = useState('');
    const [dropOff, setDropOff] = useState('');
    const [dropOffValue, setdropOffValue] = useState('');
    const [fullName, setfullName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState("");
    const options = [

        { label: '20ft', value: '20ft' },

        { label: '40ft', value: '40ft' },

        { label: '40HC', value: '40HC' },

    ];

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const dropOffOptions = [

        { label: 'Single drop off', value: 'Single drop off' },

        { label: 'Multiple location drop', value: 'Multiple location drop' },
    ];
    const handleDropOffChange = (event: any) => {
        setdropOffValue(event.target.value);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const body = {
            email: email,
            fullName: fullName,
            containerSize: value,
            origin: origin,
            dropOff: dropOff,
            number: number,
            typeOfDropOff: dropOffValue,
            date: new Date(),
        }
        console.log("let's have");        
        // /.netlify/functions / cargoLogistics
        fetch('https://techport-ms.com/.netlify/functions/cargoLogistics', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
            .then(() => {
                Swal.fire({
                    text: "We will be in touch with you soon",
                });
            }).catch((error) => {
                console.log(error);
                Swal.fire({
                    text: "Sorry, an error occurred",
                });
            });
        setEmail('');
        setfullName("");
        setValue("");
        setOrigin("");
        setDropOff("");
        setdropOffValue("");
        setNumber("");

    }
    return (
        <div className="shippingField">
            <form onSubmit={handleSubmit}>
                <div className="shippingField1">
                    <input type="text" name="port-origin" placeholder="Port of Origin" value={origin}
                        onChange={(e) => setOrigin(e.target.value)} />
                    <input type="text" name="drop-off" placeholder="Drop off Location" value={dropOff}
                        onChange={(e) => setDropOff(e.target.value)} />
                    <select value={value} onChange={handleChange}  >
                        <option value='Size of Container' disabled>Size of Container</option>
                        {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                    </select>
                    <select value={dropOffValue} onChange={handleDropOffChange} >
                        <option value='Type of drop off' disabled>Type of drop off</option>
                        {dropOffOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                    </select>
                    <input type="text" name="full-name" placeholder="Enter your Full Name" value={fullName}
                        onChange={(e) => setfullName(e.target.value)} />
                </div>
                <div className="shippingField2">
                    <input type="number" name="phone-number" placeholder="Enter your Phone Number" value={number}
                        onChange={(e) => setNumber(e.target.value)} />
                    <input type="email" name="user-email" placeholder="Enter your email address" value={email}
                        onChange={(e) => setEmail(e.target.value)} />                    
                    <button type="submit" >
                        Process
                    </button>
                </div>

            </form>
        </div>
    );
}

export default CargoLogisticsButton;