import { useState } from "react";
import Pillars from "./pillars";
import "./ShippingService.css";

const CargoService = () => {
    const [showMore, setShowMore] = useState(false);
    const onShow = () => setShowMore(!showMore);

    return (
        <div className="ship">
            <h2>Our <span className="ocean-shipping">Freight Forwarding</span> and <br /> <span className="ocean-shipping">Cargo Logistics</span> Services</h2>
            <div className="shipping-service">
                <div className='shipping1'>
                    <Pillars
                        // icon="fa-solid fa-anchor"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Multi-Modal Solutions"
                        pillarCardP="Benefit from our multi-modal transportation solutions that seamlessly combine air, ocean, and ground transportation. TechPort Africa optimizes routes to ensure the most efficient and cost-effective journey for your cargo."
                    />

                    <Pillars
                        // icon="fa-sharp fa-solid fa-microchip"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Warehousing and Distribution"
                        pillarCardP="Streamline your supply chain with our warehousing and istribution services.TechPort Africa provides secure storage, inventory management, and distribution solutions,ensuring your
cargo is positioned strategically for timely delivery."
                    />

                    <Pillars
                        // icon="fa-sharp fa-solid fa-star"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Customized Supply Chain Solutions"
                        pillarCardP="TechPort Africa understands that every supply chain is unique. Our team collaborates with you to design customized logistics solutions that align with your business goals, enhance
efficiency, and reduce overall costs."
                    />
                </div>
                <div className='shipping2'>
                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Port-to-Port/Destination (P2P/D)"
                        pillarCardP="TechPort Africa simplifies your global trade journey with our efficient Port-to-Port and Destination Logistics solutions. Seamlessly connecting major ports worldwide, we e nsure swift and secure cargo movement. From strategic planning to real -time tracking, our expert team manages every detail, offering a reliable and streamlined logistics experience that transcends borders"
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Order Fulfillment Services"
                        pillarCardP="From order processing to packing and shipping,TechPort Africa offers comprehensive order fulfillment services. Our logistics solutions are designed to meet the demands of e-commerce,
ensuring a seamless and timely delivery process.
"
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-solid fa-arrow-up-right-dots"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Cross-Border Logistics"
                        pillarCardP="Navigate cross-border challenges with ease. TechPort Africa specializes in cross-border logistics, managing documentation, customs clearance, and compliance to ensure smooth and
efficientcargomovement acrossinternationalborders.
"
                    /> : ""}
                </div>

                <div className='shipping3'>
                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Supply Chain Visibility"
                        pillarCardP="Gain real-time visibility into your supply chain with TechPort Africa's advanced tracking and reporting tools. Monitor your cargo at every stage,from the point of origin to the final
destination, ensuring transparency and control."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Reverse Logistics"
                        pillarCardP="As part of our commitment to sustainability, we prioritize eco-friendly practices in our ocean shipping operations. Choose TechPort Africa for responsible shipping that minimizes environmental impact."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Project Cargo Management"
                        pillarCardP="For oversized or specialized cargo, TechPort Africa provides project cargo management services. Our team has the expertise to plan and execute complex logistics projects,ensuring the safeand timely delivery of your unique shipments."
                    /> : ""}
                </div>
            </div>
            <button className="btn" onClick={onShow}>
                {showMore ? "show less" : "show more"}
            </button>
        </div>

    );
}

export default CargoService;