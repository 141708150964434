
import "./NavbarStyles.css";
import { useState } from "react";
import HomeImg from "../assets/tp1-removebg-preview.png"
import Dropdown from "./Dropdown";


const Navbar = () => {
    const [clicked, setClicked] = useState(false);
    const [isNavbarVisible, setIsNavbarVisible] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const handleClick = () => setClicked(!clicked);
    const closeMobileMenu = () => setClicked(false)

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    };

    const onMouseLeave = () => {
        if (window.innerWidth < 960) {
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    };

    const toggleNavItems = () => {
        setIsNavbarVisible(!isNavbarVisible);
    }

    return (
        <div>
            <nav className="NavbarItemsMobile">
                <a href="/" >
                    <img alt="tp-africa logo" className="navbar-logo" src={HomeImg} />
                </a>

                <div className="menu-icons" onClick={handleClick}>
                    <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <a href="/about" className='nav-links' onClick={closeMobileMenu}>
                            <i className="fa-solid fa-circle-info"></i>About
                        </a>
                    </li>
                    <li
                        className='nav-item'

                    >
                        <a
                            className='nav-links'
                            onClick={toggleNavItems}
                        >
                            <i className="fa-solid fa-briefcase"></i>Services <i className='fas fa-caret-down' />
                        </a>
                        {isNavbarVisible && <Dropdown />}
                    </li>
                    <li className='nav-item'>
                        <a
                            href='/contact'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            <i className="fa-solid fa-address-book"></i>Contact
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a
                            href='/get-a-quote'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            <i className="fa-solid fa-barcode"></i>Get a Quote
                        </a>
                    </li>
                </ul>

            </nav>
            <nav className="NavbarItems">
                <a href="/" >
                    <img alt="tp-africa logo" className="navbar-logo" src={HomeImg} />
                </a>

                <div className="menu-icons" onClick={handleClick}>
                    <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <a href="/about" className='nav-links' onClick={closeMobileMenu}>
                            <i className="fa-solid fa-circle-info"></i>About
                        </a>
                    </li>
                    <li
                        className='nav-item'
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <a
                            // href=''
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            <i className="fa-solid fa-briefcase"></i>Services <i className='fas fa-caret-down' />
                        </a>
                        {dropdown && <Dropdown />}
                    </li>
                    <li className='nav-item'>
                        <a
                            href='/contact'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            <i className="fa-solid fa-address-book"></i>Contact
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a
                            href='/get-a-quote'
                            className='nav-links'
                            onClick={closeMobileMenu}
                        >
                            <i className="fa-solid fa-barcode"></i>Get a Quote
                        </a>
                    </li>
                </ul>

            </nav>
        </div>
    );
}
export default Navbar;
