import { SetStateAction, useState } from "react";
import "./OceanShippingButton.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const OceanShippingButton = () => {
    const [date, setDate] = useState(new Date());
    const [value, setValue] = useState('');
    const [typeValue, setTypeValue] = useState('');
    const [origin, setOrigin] = useState('');
    const [dropOff, setDropOff] = useState('');
    
    const [fullName, setfullName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState("");
    const options = [

        { label: '20ft', value: '20ft' },

        { label: '40ft', value: '40ft' },

        { label: '40HC', value: '40HC' },

    ];
   
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const typeOptions = [

        { label: 'Dry', value: 'Dry' },

        { label: 'Reefer', value: 'Reefer' },

    ];

    const handleTypeChange = (event: any) => {

        setTypeValue(event.target.value);

    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const body = {
            email: email,
            fullName: fullName,
            containerSize: value,
            containerType: typeValue,
            origin: origin,
            dropOff: dropOff,
            number: number,            
            bookingDate: date,
            date: new Date(),
        }
        
        // /.netlify/functions / oceanShipping
        fetch('https://techport-ms.com/.netlify/functions/oceanShipping', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
            .then(() => {
                Swal.fire({
                    text: "We will be in touch with you soon",
                });
            }).catch((error) => {
                console.log(error);
                Swal.fire({
                    text: "Sorry, an error occurred",
                });
            });
        setEmail('');
        setfullName("");
        setValue("");
        setOrigin("");
        setDropOff("");
        setTypeValue("");
        setNumber("");      

    }

    return (
        <div className="shippingField">
            <form onSubmit={handleSubmit}>
                <div className="shippingField1">
                    <input type="text" name="port-loading" placeholder="Port of Loading" value={origin}
                        onChange={(e) => setOrigin(e.target.value)} />
                    <input type="text" name="port-destination" placeholder="Port of Destination" value={dropOff}
                        onChange={(e) => setDropOff(e.target.value)} />

                    <select value={value} onChange={handleChange} >
                        <option value='Size of Container' disabled>Size of Container</option>
                        {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                    </select>  
                    <select value={typeValue} onChange={handleTypeChange}  >
                        <option value='Type of Container' disabled>Type of Container</option>
                        {typeOptions.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))}
                    </select>                                         
                    <input type="text" name="full-name" placeholder="Enter your Full Name" value={fullName}
                        onChange={(e) => setfullName(e.target.value)} />
                </div>                
                <div className="shippingField2">
                    <input type="text" name="phone-number" placeholder="Enter your Phone Number" value={number}
                        onChange={(e) => setNumber(e.target.value)} />
                    <input type="text" name="user-email" placeholder="Enter your email address" value={email}
                        onChange={(e) => setEmail(e.target.value)} /> 
                    <DatePicker className="date" selected={date} onChange={(date) => setDate(date!)} />                    
                    <button type="submit" >
                        Process
                    </button>
                </div>
                
            </form>
        </div>
    );
}

export default OceanShippingButton;