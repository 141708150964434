import "./Header.css";

const Header = (props: any) => {
    return (
        <div>
            <img className="headerImage" alt={props.alternate} src={props.headerImage} />
            <div className="header">
                <h1>{props.headerText}</h1>
            </div>
        </div>
    );
}

export default Header;