
import FaqSection from "../components/FaqSection";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Logistics from "../components/Logistics";
import Navbar from "../components/Navbar";

import Strength from "../components/Strength";


const Home = () => {
    return (
        <>
            <Navbar />
            <Hero
                cName="hero"
                heroImage="https://images.pexels.com/photos/2231744/pexels-photo-2231744.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                // heroTitle="A whole new shipping experience"
                subTitle="It's time to get on board!"
                heroButtonText="Get in Touch"
                url="/contact"
                btnClass="show"
            />
            {/* <SeamlessAutomation /> */}
            <Logistics />
            <Strength />
            <FaqSection />
            {/* <Faq /> */}
            <Footer />
        </>
    );
}

export default Home;