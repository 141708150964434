// export const MenuItems = [

//     {
//         title: "About",
//         url: "/about",
//         cName: "nav-links",
//         icon: "fa-solid fa-circle-info"
//     },
//     {
//         title: "Services",
//         url: "/services",
//         cName: "nav-links",
//         icon: "fa-solid fa-briefcase",

//     },
//     {
//         title: "Contact",
//         url: "/contact",
//         cName: "nav-links",
//         icon: "fa-solid fa-address-book"
//     },
// ]

export const MenuItems = [
    {
        title: 'Ocean Shipping Solutions',
        path: '/ocean-shipping',
        cName: 'dropdown-link',
        icon: "fa-sharp fa-solid fa-ship"
    },
    {
        title: 'Air Freight Excellence',
        path: '/air-freight',
        cName: 'dropdown-link',
        icon: "fa-solid fa-jet-fighter"
    },
    {
        title: 'Seamless Cargo Logistics',
        path: '/cargo-logistics',
        cName: 'dropdown-link',
        icon: "fa solid fa-truck"
    },
];