import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Services from './routes/Services';
import Shipping from './routes/Shipping';
import Freight from './routes/Freight';
import Cargo from './routes/Cargo';
import Faq from './routes/Faq';
import Order from './routes/Order';

function App() {  
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/services' element={<Services />} />
        <Route path='/ocean-shipping' element={<Shipping />} />
        <Route path='/air-freight' element={<Freight />} />
        <Route path='/cargo-logistics' element={<Cargo />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/get-a-quote' element={<Order />} />
      </Routes>
      <a       
        href= {process.env.REACT_APP_WHATSAPP_LINK}
        className="whatsapp_float"        
      >
        <i className="fa-brands fa-whatsapp"></i>        
      </a>
    </div>
  );
}

export default App;
