import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ServiceIntro from "../components/ServicesIntro";

const Services = () => {
    return (
        <>
            <Navbar />
            <ServiceIntro />
            <Footer />
        </>
    );
}

export default Services;