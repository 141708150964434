import { useState } from "react";
import "./Footer.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";



const Footer = () => {
    const [email, setEmail] = useState('');    
   
    const handleSubmit = async (e:any) => {
        e.preventDefault();
        const body = {
            email: email,            
            date: new Date(),
        }
        
        // /.netlify/functions / subscribe
        fetch('https://techport-ms.com/.netlify/functions/subscribe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
            .then(() => {            
                Swal.fire({                    
                    text: "Thank you for subscribing to our newsletter, we would be sure to keep you posted on our services and offers",                    
                });
                               
            }).catch((error) => {
                console.log(error);
                Swal.fire({
                    text: "Sorry, an error occurred",
                });                
            });
        setEmail('');

    }
    
    return (
        <div className="footer">
            <div className="blank">
            </div>
            {/* <div className="subscribe-main" id="sMain"> */}
            <div id="subscribe-main" >
                <div className="subscribe-text">
                    <h2 >Get notified on any updates</h2>
                    <p>Stay up to date on announcements, offers and new products</p>
                </div>
                <div className="input">
                    { <form onSubmit={handleSubmit}>
                        <input type="text" name="user-email" placeholder="Enter your email address" value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        <button type="submit" >
                            Subscribe
                        </button>
                    </form> }

                </div>

            </div>

            <div className="bottom1">
                <div className="footext">
                    <p>Company</p>
                    <a href="./about">Tech Port Africa</a>
                    <a href="./get-a-quote">Get a Quote</a>
                </div>

                <div className="footext">
                    <p>Experience</p>
                    <a href="./contact">Contact us</a>
                    <a href="./faq">FAQs</a>
                </div>

                <div className="footext">
                    <p>Services</p>
                    <a href="./ocean-shipping">Ocean shipping</a>
                    <a href="./air-freight">Air freight</a>
                    <a href="./cargo-logistics">Cargo logistics/freight forwarding</a>
                </div>
            </div>

            <div className="bottom2">
                <div className="footext">
                    <p>Legal</p>
                    <a href="./">Confidentiality</a>
                    <a href="./">Development guidelines</a>
                    <a href="./">Conditions</a>
                    <a href="./">Data protection</a>
                </div>

                <div className="footext">
                    <p>Resources</p>
                    <a href="./faq">FAQs</a>
                    <a href="#subscribe-main">Newsletter Subscription</a>
                </div>

                <div className="footext">
                    <p>Contact</p>
                    <a className="tDecor">Contactus@techport-ms.com</a>
                    <a className="tDecor">tpafrica@techport-ms.com</a>
                    <a>+2348039829851</a>
                    <a>+447823894115</a>
                    <a>Our call lines are available 24/7</a>
                </div>
            </div>

            <div className="socialIcons">
                <div>

                    <p>Follow us</p>
                </div>
                <div>
                    <a href="https://www.linkedin.com/company/tp-africa/">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://www.instagram.com/techport_ms?igshid=NmQ5Y2ExZGE1MQ==">
                        <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a href="https://x.com/Techport_MS?t=mc2RExBJKGIjSvrNuKtRcg&s=09">
                        <i className="fa-brands fa-twitter-square"></i>
                    </a>
                </div>
            </div>
            <div className="divider">
                <hr className="divider"></hr>
                <p className="copyRight">&copy; 2023 Tech Port Africa. All rights reserved.</p>
            </div>

        </div>
    );

}

export default Footer;