import Footer from "../components/Footer";
import FreightService from "../components/FreightServices";
import Navbar from "../components/Navbar";
import PictureText from "../components/PictureText";
import Strength from "../components/Strength";

const Freight = () => {
    return (
        <>
            <Navbar />
            <PictureText
                textH2="Air Freight Excellence with TechPort Africa"
                textP="TechPort Africa takes pride in offering top-tier air freight services, providing swift, secure, and efficient transportation solutions to meet the demands of today's fast-paced global
commerce. Elevate your cargo experience with our comprehensive air freight services that prioritize speed, reliability, and p recision."
                img1="https://images.unsplash.com/photo-1596137713472-5a0c489235f0?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <FreightService />
            <Strength />
            <Footer />
        </>
    );
}

export default Freight;