import Expertise from '../assets/expertise.png';
import Global from '../assets/global.png';
import Solutions from '../assets/solutions.png';
import Tech from '../assets/tech.png';
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import "./AboutText.css";
import ChooseUs from "./ChooseUs";
import Offer from './Offer';
import TwoColumns from './TwoColumns';
import Pillars from './pillars';
const AboutText = () => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["0 1", "1.33 1"]
    });
    const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
    const opacity = useTransform(scrollYProgress, [0, 1], [0.6, 1]);
    return (
        <div className="about-main">
            <div className="about">
                <h1>Welcome to <span className='techPort'>TechPort Africa</span> – <br /> <span className='techPort-small'>Your Gateway to Seamless Maritime Solutions!</span></h1>
            </div>

            <div className='twoColums'>
                <TwoColumns
                    column="who"
                    h2="Who We Are"
                    p="TechPort Africa, a proud subsidiary of TechPort Maritime Services, sets sail as a dynamic maritime company founded in 2022 and officially registered in 2023. Nestled in the heart of maritime activities, our head office is strategically located in Lagos, Nigeria, the bustling hub of trade and commerce in West Africa. We embark on a mission to redefine excellence in the maritime industry, aspiring to be the leading shipping organization across the African continent."
                />
                <TwoColumns
                    column="expertise"
                    h2="Our Expertise"
                    p="At TechPort Africa, we specialize in Maritime Ocean Cargo, Air Freight, and Freight Forwarding/Cargo Logistics Services. With a commitment to innovation, reliability, and efficiency, we navigate the complexities of global trade to deliver tailored solutions that meet the diverse needs of our clients.
"
                />
            </div>

            <div className="container">
                <img alt="containers" src="https://images.unsplash.com/photo-1601897690942-bcacbad33e55?q=80&w=2952&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
            </div>

            <div className="mission-vision">
                <div className="mission-vision-card">
                    <div className="mvCards">
                        <div className="mvCards-text">
                            <h1>
                                OUR MISSION
                            </h1>
                            <p>At TechPort Africa Cabotage LTD, our mission is to revolutionize the logistics and shipping industry in Africa. We aim to facilitate seamless cargo movement, enhance supply chain efficiency, and support economic growth by
                                providing top-tier services that meet the unique needs of our diverse clientele.</p>
                        </div>


                    </div>

                    <div className="mvCards">
                        <div className="mvCards-text">
                            <h1>
                                OUR VISION
                            </h1>
                            <p>At TechPort Africa, our vision is to be the unrivaled leader in the maritime industry across the African continent. We aspire to redefine the narrative of shipping, transforming it into a seamless and dynamic experience that catalyzes trade, fosters economic growth, and forges enduring connections within and beyond our shores .
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <motion.div className="pillars" ref={ref} style={
                {
                    scale: scaleProgress,
                    opacity: opacity
                }
            }>
                <h2>Pillars of Our Vision</h2>
                <div className='pillar1'>
                    <Pillars
                        icon="fa-solid fa-anchor"
                        pillarCard="pillarCard"
                        pillarText="pillarText"
                        pillarCardH1="Excellence in Maritime Solutions"
                        pillarCardP="We envision setting the gold standard for excellence in maritime solutions. By leveraging our extensive experience and innova tive approach, we aim to consistently exceed expectations, delivering unparalleled services that redefine the benchmark for the industry."
                    />

                    <Pillars
                        icon="fa-sharp fa-solid fa-microchip"
                        pillarCard="pillarCard"
                        pillarText="pillarText"
                        pillarCardH1="Pioneering Technological Innovation"
                        pillarCardP="As trailblazers in the maritime domain, we envision embracing and leading technological innovations that revolutionize the shipping landscape. By staying at the forefront of advancements, we empower businesses with cutting-edge solutions that enhance efficiency, transparency, and overall operational excellence."
                    />

                    <Pillars
                        icon="fa-sharp fa-solid fa-star"
                        pillarCard="pillarCard"
                        pillarText="pillarText"
                        pillarCardH1="Pan-African Leadership"
                        pillarCardP="Our vision extends beyond national borders. We aspire to be the go-to shipping organization for businesses and industries across Africa. By building a vast and interconnected network, we seek to facilitate seamless trade and elevate the continent's standing in global commerce."
                    />
                </div>
                <div className='pillar2'>
                    <Pillars
                        icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="pillarCard"
                        pillarText="pillarText"
                        pillarCardH1="Customer-Centric Approach"
                        pillarCardP="At the core of our vision is a commitment to putting our customers first. We envision TechPort Africa as synonymous with reliability, responsiveness, and a customer-centric ethos. By understanding and anticipating the unique needs of our clients, we aim to create lasting partnershipsbuilt on trust and satisfaction."
                    />

                    <Pillars
                        icon="fa-sharp fa-solid fa-globe"
                        pillarCard="pillarCard"
                        pillarText="pillarText"
                        pillarCardH1="Empowering Global Connectivity"
                        pillarCardP="Our vision extends to being a catalyst for global connectivity. By fostering strategic partnerships, embracing diversity, and facilitating the fluid movement of
                        goods, we aim to position TechPort Africa as a vital link in the global supply chain, contributing to the economic vibrancy of nations worldwide."
                    />

                    <Pillars
                        icon="fa-solid fa-arrow-up-right-dots"
                        pillarCard="pillarCard"
                        pillarText="pillarText"
                        pillarCardH1="Sustainable Growth and Responsibility"
                        pillarCardP="As we lead the maritime industry, we envision doing so with a keen sense of responsibility. Our vision includes embracing sus tainable practices, reducing environmental impact, and contributing to the communities we serve. We see our growth as not only a reflection of success but also a testament to our commitment to ethical and responsible business practices."
                    />
                </div>
            </motion.div>

            <Offer />

            <div className="choose-us">
                <div className="heading">
                    <h1>Why Choose TechPort Africa</h1>
                </div>
                <div className="chooseus-grid">
                    <ChooseUs
                        circle="circle"
                        source={Expertise}
                        // cirlcleIcon="fas fa-bars"
                        chooseCard="chooseCard"
                        cardText="cardText"
                        chooseCardH1="Unparalleled Expertise"
                        chooseCardP="With years of experience and a team of industry experts, we bring a wealth of knowledge to
                     every project we undertake. Our dedication to excellence is what sets us apart."
                    />

                    <ChooseUs
                        source={Global}
                        chooseCard="chooseCard"
                        circle="circle"
                        // cirlcleIcon="fas fa-bars"
                        cardText="cardText"
                        chooseCardH1="Global Reach"
                        chooseCardP="We have a vast network of partners, ensuring that your cargo reaches its destination,
                         whether it's across the continent or around the world."
                    />

                    <ChooseUs
                        source={Tech}
                        circle="circle"
                        // cirlcleIcon="fas fa-bars"
                        chooseCard="chooseCard"
                        cardText="cardText"
                        chooseCardH1="Cutting-Edge Technology"
                        chooseCardP="We leverage the latest technology to optimize every aspect of 
                        your logistics and shipping needs, providing real-time visibility and control."
                    />

                    <ChooseUs
                        source={Solutions}
                        circle="circle"
                        // cirlcleIcon="fas fa-bars"
                        chooseCard="chooseCard"
                        cardText="cardText"
                        chooseCardH1="Customized Solutions"
                        chooseCardP="We understand that each business is unique. That's why we offer tailor-made solutions to suit 
                        your cargo, freight, and shipping requirements."
                    />
                </div>

            </div>

        </div>
    );
}

export default AboutText;