
import './FaqComp.css';
import { FaqData } from "./FaqData";
import { useState } from 'react';
import Header from './Header';


const Faq = () => {
    const [selected, setSelcted] = useState(null);
    const toggle = (i: any) => {
        if (selected === i) {
            return setSelcted(null);
        }

        setSelcted(i);
    }
    return (
        <div className='all'>
            <Header
                headerImage='https://images.unsplash.com/photo-1592963219385-53b52b371dd1?q=80&w=2836&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                headerText="FAQ"
            />
            <div className='wrapper'>

                <div className="accordion">
                    {FaqData.map((item, i) => {
                        return (
                            <div className='item'>
                                <div className='title' onClick={() => toggle(i)}>
                                    <h2>{item.title}</h2>
                                    <span className='selectedIcon'>{selected === i ? "-" : "+"}</span>
                                </div>
                                <div className={selected === i ? "content show" : 'content'} >
                                    {item.answer}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>

    );
}
export default Faq;