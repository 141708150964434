// import "./StrengthCards.css";

import { AnimatedText } from "./AnimatedText";

const StrengthCards = (props: any) => {
    return (

        <div className={props.strengthCard}>
            <div className={props.cardText}>
                <AnimatedText
                    text={props.strengthH1}
                    el="h1"
                />
                {/* <h1>{props.strengthH1}</h1> */}
                <p>{props.strengthP}</p>
            </div>
        </div>
    );
}

export default StrengthCards;