import "./PictureText.css";

const PictureText = (props: any) => {
    return (
        <div className="services-main">
            <div className="pictureText">
                <div className="servicesImage">
                    <img alt={props.alt} src={props.img1} />
                </div>

                <div className="serviceText">
                    <h2>{props.textH2}</h2>
                    <p>{props.textP}</p>
                </div>
            </div>
        </div>

    );
}

export default PictureText;