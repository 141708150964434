import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PictureText from "../components/PictureText";
import ShippingService from "../components/ShippingService";
import Strength from "../components/Strength";

const Shipping = () => {
    return (
        <>
            <Navbar />
            <PictureText
                textH2="Ocean Shipping Solutions by TechPort Africa"
                textP="At TechPort Africa, we understand the significance of reliable and efficient ocean shipping in today's globalized world. Our comprehensiv e ocean shipping solutions are designed
                to streamline your cargo transportation, ensuring it reaches its destination securely and on time. Partner with us for a seam less voyage that transcends boundaries."
                img1="https://plus.unsplash.com/premium_photo-1661964124175-daf5aa598b85?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <ShippingService />
            <Strength />
            <Footer />
        </>
    );
}

export default Shipping;