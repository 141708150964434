import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import OrderContainer from "../components/OrderContainer";
// import Strength from "../components/Strength";

const Order = () => {
    return (
        <>
            <Navbar />
            <OrderContainer />
            {/* <Strength />      */}
            <Footer />
        </>
    );
}

export default Order;