import CargoService from "../components/CargoServices";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import PictureText from "../components/PictureText";
import Strength from "../components/Strength";

const Cargo = () => {
    return (
        <>
            <Navbar />
            <PictureText
                textH2="Seamless Cargo Logistics/Freight forward with TechPort Africa"
                textP="TechPort Africa is your trusted partner in Freight Forwarding and Cargo Logistics, providing end-to-end solutions that go beyond traditional shipping. We seamlessly integrate logistics into your supply chain, offering tailored services that ensure the efficient movement of your cargo from origin to destination. Experience the difference with TechPort Africa's comprehensive logistics solutions."
                img1="https://plus.unsplash.com/premium_photo-1661880749508-71d9e7170508?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <CargoService />
            <Strength />
            <Footer />
        </>
    );
}

export default Cargo;