import './ContactData.css';

const ContactData = (props: any) => {
    return (
        <div className="contactCard">
            <div className="contactText">
                <h2>{props.contactH2}</h2>
                <a href={props.href} className='contactA'>{props.contactA1}</a><br />
                <a href={props.href} className='contactB'>{props.contactA2}</a>
            </div>
            <div className="contactAvatar">
                <img alt={props.contactAlt} src={props.contactSrc} />
            </div>
        </div>
    );

}

export default ContactData;