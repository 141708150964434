
export const FaqData = [
    {
        title: "What services do your shipping company provide?",
        answer: "We offer both ocean shipping and air shipping services. Our ocean shipping includes Full Container Load (FCL) and Less tha n Container Load (LCL) options, while our air shipping services cover a range of cargo types."
    },
    {
        title: "How can I obtain a shipping quote?",
        answer: "You can request a shipping quote by filling out our online form on our website or by contacting our customer service team. Provide details such as the type and size of the cargo, origin, destination, and preferred shipping method."
    },
    {
        title: "What are the transit times for ocean and air shipping?",
        answer: "Transit times vary depending on the shipping method and the specific route. Our team will provide you with estimated trans it times based on your shipment details."
    },
    {
        title: "What is the difference between FCL and LCL for ocean shipping?",
        answer: "FCL (FullContainerLoad) means you have exclusive use of a shipping container, while LCL (LessthanContainerLoad) involves sharing a container with other shipments. FCL is generally faster but may be more cost- effective for smaller shipments."
    },
    {
        title: "How do I track my shipment?",
        answer: "We provide a tracking system on our website where you can input your shipment details to get real-time updates. Additionally, our customer service team is available to assist you."
    },
    {
        title: "What customs documentation is required for international shipping?",
        answer: "Customs documentation varies by country, but common documents include a commercial invoice, packing list, bill of lading or air waybill, and any required certificates.Our team will guide you through the documentation process."
    },
    {
        title: "Are there restrictions on the types of goods that can be shipped?",
        answer: "Yes, certain goods may be subject to restrictions or require special handling. It's important to inform us about the nature of your cargo, and we will provide guidance on any applicable regulations."
    },
    {
        title: "What security measures are in place for my shipment?",
        answer: "We prioritize the security of your shipment. Our facilities are equipped with surveillance systems, and we work closely wi th trusted carriers and partners to ensure the safety of your cargo.Goods in Transit / Parcel shipments are insured."
    },
    {
        title: "How are shipping costs determined?",
        answer: "Shipping costs are influenced by factors such as the weight and volume of the cargo, shipping method, distance, and any additional services required. Our team will provide you with a detailed breakdown of costs."
    },
    {
        title: "What insurance options are available for my shipment?",
        answer: "We offer cargo/parcel/GIT insurance options to protect your shipment against loss or damage. Our team can provide details on available insurance coverage and assist you in selecting the appropriate option."
    },
    {
        title: "Can you handle oversized or special cargo?",
        answer: "Yes, we have experience handling oversized and special cargo. Please provide details about your cargo, and our team will assess the requirements and provide a tailored solution."
    },
    {
        title: "How do I contact customer support?",
        answer: "You can reach our customer support team through our website's contact form, by email, or by phone. Our contact details are available on the 'Contact Us' page."
    }
]