import AboutText from "../components/AboutText";

import FaqSection from "../components/FaqSection";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const About = () => {
    return (
        <>
            <Navbar />
            <AboutText />
            <FaqSection />
            {/* <Faq /> */}
            <Footer />
        </>
    );
}

export default About;