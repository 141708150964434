import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";

import Navbar from "../components/Navbar";

const Contact = () => {
    return (
        <>
            <Navbar />
            {/* <Header
                headerImage='https://images.unsplash.com/photo-1592963219385-53b52b371dd1?q=80&w=2836&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                headerText="CONTACT"
            /> */}
            <ContactSection />
            <Footer />
        </>
    );
}

export default Contact;