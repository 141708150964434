
const ChooseUs = (props: any) => {
    return (

        <div className={props.chooseCard}>
            <div className={props.circle}>
                <i className={props.icon}></i>
                <img src={props.source} className={props.cirlcleIcon} alt={props.alt} />
            </div>
            <div className={props.cardText}>
                <h3>{props.chooseCardH1}</h3>
                <p>{props.chooseCardP}</p>
            </div>
        </div>
    );
}

export default ChooseUs;