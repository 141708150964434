import { useState } from "react";
import Pillars from "./pillars";
import "./ShippingService.css";

const FreightService = () => {
    const [showMore, setShowMore] = useState(false);
    const onShow = () => setShowMore(!showMore);

    return (
        <div className="ship">
            <h2>Our <span className="ocean-shipping">Air Freight</span> Services</h2>
            <div className="shipping-service">
                <div className='shipping1'>
                    <Pillars
                        // icon="fa-solid fa-anchor"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Express Air Cargo"
                        pillarCardP="Choose our express air cargo services for urgent shipments that demand the fastest transit times. TechPort Africa ensures your time-sensitive cargo reaches its destination promptly and securely."
                    />

                    <Pillars

                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Consolidated Air Freight"
                        pillarCardP="Benefit from cost-effective solutions with our consolidated air freight services. Your cargo shares space with other shipments,optimizing efficiency and reducing overall
shipping costs without compromising on reliability."
                    />

                    <Pillars
                        // icon="fa-sharp fa-solid fa-star"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Global Air Network"
                        pillarCardP="TechPort Africa's extensive global air network connects you to major airports and destinations worldwide. With our strategic partnersh ips, we offer a seamless and
efficient air freight experience."
                    />
                </div>
                <div className='shipping2'>
                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Customs Clearance Expertise"
                        pillarCardP="Our team of experienced customs brokers handles all aspects of customs clearance for air freight shipments. From documentation to compliance, we ensure a smooth
and efficient customs process for your cargo.
"
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Real-Time Tracking and Monitoring"
                        pillarCardP="Stay informed and in control with our real-time tracking and monitoring solutions.Track your cargo at every stage of the journey,receive timely updates,and have peace
of mind knowing the location and status of your shipment."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-solid fa-arrow-up-right-dots"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Specialized Handling"
                        pillarCardP="TechPort Africa understands that certain cargo requires specialized handling. Whether it's high-value goods, perishables, or sensitive equipment, our air freight services
are equipped to meet your unique shipping needs.
"
                    /> : ""}
                </div>

                <div className='shipping3'>
                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Door-to-Door Solutions"
                        pillarCardP="Experience end-to-end convenience with our door-to-door air freight solutions. TechPort Africa manages the entire shipping process, from pickup at the origin to delivery
at the destination, providing a seamless shipping experience."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Flexible Scheduling and Routing"
                        pillarCardP="Our air freight services offer flexibility in scheduling and routing, allowing you to choose the options that best suit your timeline and budget."
                    /> : ""}


                </div>
            </div>
            <button className="btn" onClick={onShow}>
                {showMore ? "show less" : "show more"}
            </button>
        </div>

    );
}

export default FreightService;