import ContactData from "./ContactData";
import './ContactSection.css';
import email from '../assets/email.png';
import call from '../assets/call.png';
import question from '../assets/question.png';
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";



const ContactSection = () => {
    const [fullName, setfullName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const body = {
            email: email,
            fullName: fullName,
            subject: subject,
            message: message,
            date: new Date(),
        }        
        // /.netlify/functions / contact
        fetch('https://techport-ms.com/.netlify/functions/contact', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
        })
            .then(() => {
                Swal.fire({
                    text: "Thank you for contacting us, we would reply soon. You can call our numbers if it is urgent",
                });
            }).catch((error) => {
                console.log(error);
                Swal.fire({
                    text: "Sorry, an error occurred",
                });
            });
        setEmail('');
        setfullName("");
        setSubject("");
        setMessage("");

    }
    return (
        <div>
            <div className="con">
                <div className="con1">
                    <ContactData
                        contactH2="Call us"
                        contactA1="+2348039829851"
                        contactA2="+447823894115"
                        href=""
                        contactSrc={call}
                    />
                    <ContactData
                        contactH2="Send an email"
                        // contactA1="Contactus@techport-ms.com"
                        // contactA2="tpafrica@techport-ms.com"
                        contactA2="Contactus@techport-ms.com"
                        href=""
                        contactSrc={email}
                    />
                </div>
                <div className="con2">
                    <ContactData
                        contactH2="Visit our FAQ"
                        contactA1="FAQ"
                        href="./Faq"
                        contactSrc={question}
                    />
                </div>

            </div>
            <div className="formCotainer">
                <form onSubmit={handleSubmit}>
                    <input placeholder="Full Name" value={fullName}
                        onChange={(e) => setfullName(e.target.value)} />
                    <input placeholder="Email" value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                    <input placeholder="Subject" value={subject}
                        onChange={(e) => setSubject(e.target.value)} />
                    <textarea placeholder="Message" rows={4} value={message}
                        onChange={(e) => setMessage(e.target.value)}></textarea>
                    <button type="submit">Send Message</button>
                </form>
            </div>
        </div>

    );
}

export default ContactSection;