import "./Hero.css";


const Hero = (props: any) => {
    return (
        <>
            <div className={props.cName}>
                <img className="imageHero" alt="shipping-logistics" src={props.heroImage} />
                <div className="hero-text">
                    <h1>A whole new <span className="shipping-design">shipping</span> experience</h1>
                    <p>{props.subTitle}</p>
                    {/* <a href={props.url} className={props.btnClass}>
                        {props.heroButtonText}
                    </a> */}
                </div>
            </div>
        </>
    );
}

export default Hero;
