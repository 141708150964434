import { useState } from "react";
import Pillars from "./pillars";
import "./ShippingService.css";

const ShippingService = () => {
    const [showMore, setShowMore] = useState(false);
    const onShow = () => setShowMore(!showMore);

    return (
        <div className="ship">
            <h2>Our <span className="ocean-shipping">Ocean Shipping</span> Services</h2>
            <div className="shipping-service">
                <div className='shipping1'>
                    <Pillars
                        // icon="fa-solid fa-anchor"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Full Container Load (FCL)"
                        pillarCardP="Enjoy the benefits of exclusive container space with our FCL services. Ideal for larger shipments, FCL ensures that your carg o is the sole occupant of the container, minimizing handling and optimizing security."
                    />

                    <Pillars
                        // icon="fa-sharp fa-solid fa-microchip"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Less than Container Load (LCL)"
                        pillarCardP="For smaller shipments, our LCL services offer a cost-effective solution. Your cargo shares container space with other shipments, maximizing efficiency and reducing overall
shipping costs."
                    />

                    <Pillars
                        // icon="fa-sharp fa-solid fa-star"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Global Network of Partners"
                        pillarCardP="TechPort Africa boasts a vast global network of reliable partners and carriers.Ou partnerships enable us to offer extensive coverage, connecting you to major ports
worldwide and ensuring your cargo reaches even the most remote destinations."
                    />
                </div>

                <div className='shipping2'>
                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Customs Clearance Expertise"
                        pillarCardP="Navigating customs regulations can be complex. Our team of experienced customs brokers ensures that your cargo clears customs smoothly. We handle all
documentation, tariff classifications, and compliance, facilitating a hassle -free shipping experience."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Real-Time Tracking and Visibility"
                        pillarCardP="Stay informed throughout the shipping journey with our real -time tracking and visibility solutions. Monitor your cargo's progress, receive timely updates, and have peace
of mind knowing where your shipment is at all times."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-solid fa-arrow-up-right-dots"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Flexible and Tailored Solutions"
                        pillarCardP="We understand that each shipment is unique. Our flexible and tailored solutions cater to your specific requirements. Whether it's special cargo handling, temperature-
sensitive shipments, or unique packaging needs, we have you covered."
                    /> : ""}
                </div>

                <div className='shipping3'>
                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-users-between-lines"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Competitive Pricing"
                        pillarCardP="TechPort Africa is committed to offering competitive and transparent pricing. Benefit from cost-effective solutions without compromising on the quality and reliability of
our ocean shipping services."
                    /> : ""}

                    {showMore ? <Pillars
                        // icon="fa-sharp fa-solid fa-globe"
                        pillarCard="shippingCard"
                        pillarText="pillarText"
                        pillarCardH1="Environmental Responsibility"
                        pillarCardP="As part of our commitment to sustainability, we prioritize eco-friendly practices in our ocean shipping operations. Choose TechPort Africa for responsible shipping that minimizes environmental impact."
                    /> : ""}

                </div>
            </div>
            <button className="btn" onClick={onShow}>
                {showMore ? "show less" : "show more"}
            </button>
        </div>

    );
}

export default ShippingService;