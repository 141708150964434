
const Pillars = (props: any) => {

    return (
        <div className={props.pillarCard} >
            <i className={props.icon}></i>
            {/* <img alt={props.alt} src={props.src} /> */}
            <div className={props.pillarText}>
                <h3>{props.pillarCardH1}</h3>
                <p>{props.pillarCardP}</p>
            </div>
        </div>
    );
}

export default Pillars;